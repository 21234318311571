<template>
  <van-form class="item_list" @submit="onSubmit">
    <van-nav-bar
      title="出库"
      left-arrow
      safe-area-inset-top
      @click-left="navBack"
    />
    <van-field
      v-model="username"
      name="receiver"
      label="收货人"
      placeholder="请输入收货人"
      :rules="[{ required: true }]"
      class="info_item mt-10"
    />
    <!-- <van-field
      v-model="userphone"
      name="phone"
      label="电话："
      placeholder="请输入电话"
      :rules="[{ pattern }]"
      :rules="[{ required: true }]"
      class="info_item"
    /> -->
    <van-field
      v-model="userphone"
      name="phone"
      label="电话："
      placeholder="请输入电话"
      :rules="[{ required: true }]"
      class="info_item"
    />
    <div class="info_zone">
      <div class="info_zone_price">
        {{ '总金额：¥ ' + totalprice }}
      </div>
      <div class="info_zone_weight">
        {{ '总重量： ' + totalweight + 'kg' }}
      </div>
    </div>
    <van-list class="fixheight">
      <item-card-goods
        v-for="(item, index) in deliveGoodsList"
        :key="index"
        :editable="true"
        :goods="item.goods"
        :cart-num="item.num"
        class="class_item_card"
        @delete="deleteGoods"
        @stepChange="stepChange"
      />
      <van-button
        icon="add"
        class="class_item_card class_item_card_btn"
        native-type="button"
        @click="click_add"
      >
        添加商品
      </van-button>
    </van-list>
    <div class="buttonzone">
      <van-button
        class="buttonzone_btn"
        round
        type="default"
        native-type="button"
        @click="onCancel"
      >
        取消
      </van-button>
      <van-button
        class="buttonzone_btn"
        :loading="isLogining"
        round
        type="primary"
        native-type="submit"
      >
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import {
  List,
  Cell,
  CellGroup,
  Field,
  Form,
  Dialog,
  Toast,
  NavBar,
  Button
} from 'vant'
import ItemCardGoods from '../../../components/item-card-goods'
import { mobileReg } from '@/common/regexp'
import { outstore } from '@/api/inventory'
import handleErrorMsg from '@/mixins/errorhandle'
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [List.name]: List,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Form.name]: Form,
    [Toast.name]: Toast,
    [CellGroup.name]: CellGroup,
    [Dialog.Component.name]: Dialog.Component,
    [ItemCardGoods.name]: ItemCardGoods
  },
  mixins: [handleErrorMsg],
  data() {
    return {
      transitionName: 'van-slide-right',
      editable: false,
      isLogining: false,
      isConfirm: false,
      username: '',
      userphone: '',
      pattern: mobileReg
    }
  },
  computed: {
    ...mapState(['deliveGoodsList']),
    totalprice() {
      let price = this.deliveGoodsList.reduce((sum, v) => {
        return sum + v.goods.shop_price * v.num
      }, 0)
      price = parseFloat(price).toFixed(2)
      return price
    },
    totalweight() {
      let weight = this.deliveGoodsList.reduce((sum, v) => {
        return sum + v.goods.weight * v.num
      }, 0)
      weight = parseFloat(weight).toFixed(2)
      return weight
    }
  },
  created() {
    this.mSaveDeliveGoodsList([])
  },
  beforeRouteLeave(to, from, next) {
    if (this.isConfirm) {
      to.meta.isApply = true
    }
    next()
  },
  methods: {
    ...mapMutations(['mSaveDeliveGoodsList']),
    toggleFilterModal() {},
    navBack() {
      this.$router.go(-1)
    },
    onPpReset() {
      this.popupshow = false
      console.log('onPpReset')
    },
    async onSubmit(e) {
      console.log('onSubmit')
      console.log(e)
      if (this.deliveGoodsList.length === 0) {
        Dialog.alert({
          title: '商品单为空',
          message: '请使用添加商品按钮添加商品'
        }).then(() => {
          return
        })
      }
      try {
        this.isLogining = true
        Toast.loading({
          message: '加载中...',
          duration: 5000,
          forbidClick: true
        })
        let goods = this.deliveGoodsList.map(i => {
          return { store_id: i.goods.id, num: i.num }
        })
        await this.applyOutStored({ ...e, goods: goods })
      } catch (err) {
        console.error(err)
        Dialog.alert({
          title: '提交失败',
          message: this.handleErrorMsg(err)
        })
      } finally {
        this.isLogining = false
        Toast.clear()
      }
    },
    async applyOutStored(apdata) {
      console.log(apdata)
      const data = await outstore(apdata)
      console.log(data)
      if (data.status === 1) {
        console.log('提交成功')
        Dialog.alert({
          title: '提交成功',
          message: '出库信息已提交',
          confirmButtonText: '我知道了'
        }).then(() => {
          this.isConfirm = true
          this.navBack()
        })
      } else {
        throw data.msg
      }
    },
    onCancel() {
      console.log('onCancel')
      this.$router.go(-1)
    },
    onConfirm() {
      console.log('onConfirm')
    },
    click_add() {
      console.log('click_add')
      this.$router.push({ name: 'selectGoods' })
    },
    deleteGoods(goods) {
      console.log(goods)
      let goodsCache = this.deliveGoodsList
      goodsCache.splice(
        goodsCache.findIndex(ele => ele.goods === goods),
        1
      )
      this.mSaveDeliveGoodsList(goodsCache)
    },
    stepChange(e) {
      let goodsCache = this.deliveGoodsList
      let tmp = goodsCache.find(i => i.goods.id === e.id)
      tmp.num = e.num
      this.mSaveDeliveGoodsList(goodsCache)
    }
  }
}
</script>

<style lang="less" scoped>
@import '@/style/global.less';
.item_list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: #f5f5f5;
  .content_zone {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .info_item {
    flex: 1 0 44px;
  }
  .info_zone {
    display: flex;
    flex: 0 0 44px;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 44px;
    background-color: #ffffff;
    &_price {
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
    &_weight {
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
  }
  .buttonzone {
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 -3px 6px rgba(78, 93, 113, 0.08);
    &_btn {
      width: 110px;
      height: 38px;
      margin: 6px;
    }
  }
  .class_item_card {
    width: calc(100vw - 10px);
    margin: 5px;
    &_btn {
      color: #999999;
      background-color: #f5f5f5;
      border: 1px dashed #bababa;
    }
  }
}
.fixheight {
  height: calc(100vh - 46px - 44px - 44px - 44px - 50px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.mt-10 {
  margin-top: 10px;
}
.m-16 {
  margin: 16px;
}
.mt-30 {
  margin-top: 30px;
}

.item_list .van-tab--disabled {
  color: #000000;
}

.class_datetimepicker {
  border-radius: 5px;
}
</style>
